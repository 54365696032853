import React, { useEffect, useState } from 'react';
import axios from 'axios';

function useInvokeAPI() {
  const [data, setData] = useState({});

  const invokeUrl = async function (method, url, payload) {
    try {
      let apiData;
      if (!method || !url) return;

      if (method === 'get') {
        const response = await axios[method](url);

        if (response.data.success) {
          setData(data);
          apiData = response.data;
        }
      } else if (method === 'post') {
        const response = await axios.post(url, payload);
        if (response.data.success) {
          setData(data);
          apiData = response.data;
        }
      }
      return apiData;
    } catch (err) {
      console.log('in error block', err);
      return {};
    }
  };

  return [data, invokeUrl];
}

export default useInvokeAPI;
