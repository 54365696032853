import React from 'react';
import { Link } from 'react-router-dom';
import QrGenerator from '../projects/qrGenerator/QrGenerator';
// import AiImgGenerator from '../projects/AI_IMG_GEN/AiImgGenerator';

function Hidden() {
  return (
    <div className="hidden">
      <div id="about-content">
        <h2>
          about-me:$<span className="cursor">|</span>
        </h2>
        <p>Hi! My name is Wasif Naquvi, a passionate software developer with over 6 years of experience.</p>
        <p>
          I specialize in architecting and building scalable, reliable applications in cloud-native environments, with
          advanced expertise in AI/ML, LLM automation, and data-driven solutions.
        </p>
        <p>
          My experience spans designing end-to-end applications, solving complex problems, and delivering innovative
          solutions across domains like e-commerce, banking, gaming, and MDM/Data Unification.
        </p>
      </div>

      <div id="contact-content">
        <h2>
          contact-me:$<span className="cursor">|</span>
        </h2>
        <p>You can reach out to me at:</p>
        <ul>
          <li>Phone: +91 9986027003</li>
          <li>Email: wasif4000.wn@gmail.com</li>
        </ul>
        <p>
          Check out my work on{' '}
          <a href="https://github.com/wasifsn" target="_blank" rel="noopener noreferrer">
            GitHub
          </a>
          .
        </p>
      </div>

      <div id="tech-content">
        <h2>
          tech-stacks:$<span className="cursor">|</span>
        </h2>
        <p>My core technical expertise includes:</p>
        <ul>
          <li>
            <p>
              <strong>Full-Stack Development:</strong> MERN, MEVN, Python (Flask), Spring Boot, and Microservices
            </p>
          </li>
          <li>
            <p>
              <strong>Frontend:</strong> React.js, Next.js, Redux, Vue, Angular
            </p>
          </li>
          <li>
            <p>
              <strong>Backend:</strong> Node.js, Express, GraphQL, REST APIs, RabbitMQ, Pub/Sub, and Apache Airflow
            </p>
          </li>
          <li>
            <p>
              <strong>Cloud and DevOps:</strong> AWS, GCP, Docker, Kubernetes, CI/CD Pipelines
            </p>
          </li>
          <li>
            <p>
              <strong>AI/ML:</strong> LangChain, RAGs, LLM automation, vector embeddings, TensorFlow
            </p>
          </li>
          <li>
            <p>
              <strong>Databases:</strong> MongoDB, PostgreSQL, Redis, GBQ, Supabase
            </p>
          </li>
        </ul>
      </div>

      <div id="achievements">
        <h2>
          achievements:$<span className="cursor">|</span>
        </h2>
        <p>Some highlights of my career:</p>
        <ul>
          <li>
            <p>
              Migrated Tenant Report Tool to Apache Airflow, boosting scalability by 80% and reducing processing time by
              50%.
            </p>
          </li>
          <li>
            <p>Architected and delivered a self-service app for tenant provisioning, reducing manual effort by 60%.</p>
          </li>
          <li>
            <p>Key contributor to MyJio's engagement apps, supporting millions of users and scaling backend systems.</p>
          </li>
          <li>
            <p>Implemented AI-driven insights using LangChain, RAGs, and vector embeddings.</p>
          </li>
          <li>
            <p>
              Received multiple awards, including the Reltio Rockstar Award and Gem Award, for innovation and
              leadership.
            </p>
          </li>
        </ul>
      </div>

      <div id="services-content">
        <h2>
          services:$<span className="cursor">|</span>
        </h2>
        <p>I provide professional services and freelance expertise to help businesses and teams achieve their goals:</p>
        <ul>
          <li>
            <p>
              <strong>Full-Stack Development:</strong> Building complete, end-to-end applications using modern
              frameworks like MERN and Next.js.
            </p>
          </li>

          <li>
            <p>
              <strong>System Design:</strong> High-Level Design (HLD) and Low-Level Design (LLD) for scalable and
              reliable systems.
            </p>
          </li>
          <li>
            <p>
              <strong>UX/UI Design:</strong> Crafting seamless, user-friendly interfaces with a focus on accessibility
              and engagement.
            </p>
          </li>
          <li>
            <p>
              <strong>Data Pipelines:</strong> Designing and implementing robust data pipelines using Apache Airflow and
              GCP tools.
            </p>
          </li>
          <li>
            <p>
              <strong>Software Architecture Consulting:</strong> Offering tailored backend, frontend, and architectural
              solutions.
            </p>
          </li>
          <li>
            <p>
              <strong>AI/ML Integration:</strong> Leveraging AI and machine learning to add intelligent features to
              applications.
            </p>
          </li>
        </ul>
        <p>Let’s work together to bring your ideas to life with innovative and scalable solutions!</p>
      </div>

      <div id="side-projects">
        <h2>
          side-projects:$<span className="cursor">|</span>
        </h2>
        <p>A glimpse into some of my side projects:</p>
        <ul>
          <li>
            <Link to="/projects/qrgenerator" element={<QrGenerator />}>
              QR Generator
            </Link>{' '}
            - A tool to generate QR codes easily.
          </li>
          {/* <li>
            <Link to="/projects/aiimagegenerator" element={<AiImgGenerator />}>
              AI Image Generator
            </Link>{' '}
            - Generate stunning images with AI.
          </li> */}
        </ul>
      </div>
    </div>
  );
}

export default Hidden;
