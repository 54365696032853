import { useState, useEffect } from 'react';
import './app.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHouse } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router';
import useInvokeAPI from '../../hooks/useInvokeAPI';

const dimensions = [
  { name: 'small', value: '256x256' },
  { name: 'medium', value: '512x512' },
  { name: 'large', value: '1024x1024' },
];

const AiImgGenerator = () => {
  let navigate = useNavigate();
  const [base64, setBase64] = useState('');
  const [size, setSize] = useState('small');
  const [url, setUrl] = useState('');
  const [data, invokeUrl] = useInvokeAPI();
  const [showSpinner, setShowSpinner] = useState(false);
  const [prompt, setPrompt] = useState('');

  const resetState = () => {
    setSize((val) => '200');
  };

  useEffect(() => {
    return () => resetState();
  }, [url]);

  const throttledImgFn = () => {
    let id;
    return function () {
      setShowSpinner(true);
      if (id) return;
      id = setTimeout(() => {
        console.log('called');
        generateImg();
        id = null;
      }, 1000);
    };
  };

  const setThrottledGen = throttledImgFn();

  const generateImg = async () => {
    console.log('invoked');
    let data = await invokeUrl('post', 'http://localhost:8080/api/aiimagegen', {
      prompt,
      size,
    });
    setUrl(data.data);
    setShowSpinner(false);
    console.log(data);
  };
  return (
    <div className="qr-container">
      {' '}
      <header className="p-4 mb-10 bg-red-500">
        <div className="max-w-5xl m-auto flex align-center justify-between items-center">
          <div className="text-xl font-bold text-white">AI Image Generator</div>
          <div onClick={() => navigate('/')} className="my-icon">
            <FontAwesomeIcon icon={faHouse} />
          </div>
        </div>
      </header>
      <main>
        <div className="flex flex-col-reverse align-center justify-center m-auto md:max-w-4xl p-10 md:flex-row">
          <div className="w-full md:w-2/3 mr-24">
            <h1 className="text-3xl font-bold mb-5 md:text-5xl text-black">Image Generator</h1>
            <p className="mb-4 text-black">
              This Tool uses <strong>openai.com</strong> text to Image API and creates a new image each Time on the
              basis of the Text Provided.
            </p>
            <p className=" text-black">Enter your Text Prompt to create an Image.</p>
            <p className=" text-black font-bold mb-5">Some Examples for the Text Prompt...</p>
            <p className=" text-black">cat playing a piano.</p>
            <p className=" text-black">Rabbit driving a car.</p>

            <form
              id="generate-form"
              className="mt-4 mb-5"
              onSubmit={(e) => {
                e.preventDefault();
                setThrottledGen();
              }}
            >
              <input
                onChange={(e) => {
                  setPrompt(e.target.value);
                }}
                id="url"
                type="text"
                placeholder="Enter a Text Prompt to generate an Image"
                className="w-full border-2 border-gray-200 rounded p-3 text-grey-dark mr-2 focus:outline-none mb-5 text-black"
              />

              <select
                defaultValue="200"
                onChange={(e) => {
                  setSize(e.target.value);
                }}
                className="w-full border-2 border-gray-200 rounded p-3 text-grey-dark mr-2 focus:outline-none text-black mb-5"
                name="size"
                id="size"
              >
                {dimensions.map((el) => (
                  <option value={el.value} key={el.value} className="text-black">
                    {el.name}
                  </option>
                ))}
              </select>

              <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded flex align-center justify-center items-center">
                <svg
                  className={`animate-spin -ml-1 mr-3 h-5 w-5 text-white ${showSpinner ? '' : 'hidden'}`}
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
                Generate Image
              </button>
            </form>
            <div className="">{url && <img src={url} />}</div>
          </div>
        </div>

        <div id="generated" className="max-w-5xl m-auto flex flex-col text-center align-center justify-center mt-20">
          {/* <div
            id="spinner"
            role="status"
            className={showSpinner ? 'show' : 'hide'}
          >
            <svg
              className="inline mr-2 w-24 h-24 text-gray-200 animate-spin dark:text-gray-600 fill-pink-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <span className="sr-only">Loading...</span>
          </div> */}
        </div>
      </main>
    </div>
  );
};

export default AiImgGenerator;
