import '../App.css';
import React, { Suspense } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { ErrorBoundary } from 'react-error-boundary';
import Home from './Home';
import ErrorFallBack from './ErrorFallBack';
import QrGenerator from '../projects/qrGenerator/QrGenerator';
import AiImgGenerator from '../projects/AI_IMG_GEN/AiImgGenerator';

function App() {
  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallBack}
      onReset={() => {
        window.location.pathname = '/hhgyg'; //hack to navigate
        console.log('called');
        // reset the state of your app so the error doesn't happen again
      }}
    >
      <BrowserRouter>
        {/* {Math.random() > 0.5 && new Error('err')} */}
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/*" element={<Home />}>
              {/* <Route index element={<Home />} />
        <Route path="teams" element={<Teams />}>
          <Route path=":teamId" element={<Team />} />
          <Route path="new" element={<NewTeamForm />} />
          <Route index element={<LeagueStandings />} />
        </Route> */}
            </Route>
            <Route path="/projects/QrGenerator" element={<QrGenerator />}></Route>
            <Route path="/projects/aiimagegenerator" element={<AiImgGenerator />}></Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ErrorBoundary>
  );
}

export default App;
