import WinBox from 'winbox/src/js/winbox';
import 'winbox/dist/css/winbox.min.css';

const checkMobile = () => {
  if (/Android|webOS|iPhone|iPad|Mac|Macintosh|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }
  return false;
};

const about = () => {
  const newBox = new WinBox({
    title: 'About Me',
    minwidth: '200px',
    minheight: '200px',
    width: checkMobile() ? '100%' : '40%',
    height: checkMobile() ? '100%' : '80%',
    top: 50,
    right: 50,
    bottom: 50,
    left: 50,
    mount: document.querySelector('#about-content'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

const contact = () => {
  const newBox = new WinBox({
    title: 'About Me',
    width: checkMobile() ? '100%' : '40%',
    height: checkMobile() ? '100%' : '80%',
    top: checkMobile() ? '60' : '100',
    right: 50,
    bottom: 50,
    left: checkMobile() ? '60' : '100',
    mount: document.querySelector('#contact-content'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

const techStacks = () => {
  const newBox = new WinBox({
    title: 'Tech Stacks',
    width: checkMobile() ? '100%' : '45%',
    height: checkMobile() ? '100%' : '90%',
    top: checkMobile() ? '60' : '100',
    right: 50,
    bottom: 50,
    left: checkMobile() ? '60' : '100',
    mount: document.querySelector('#tech-content'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

const sideProjects = () => {
  const newBox = new WinBox({
    title: 'Tech Stacks',
    width: checkMobile() ? '100%' : '45%',
    height: checkMobile() ? '100%' : '90%',
    top: checkMobile() ? '60' : '100',
    right: 50,
    bottom: 50,
    left: checkMobile() ? '60' : '100',
    mount: document.querySelector('#side-projects'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

const servicesContent = () => {
  const newBox = new WinBox({
    title: 'Tech Stacks',
    width: checkMobile() ? '100%' : '45%',
    height: checkMobile() ? '100%' : '90%',
    top: checkMobile() ? '60' : '100',
    right: 50,
    bottom: 50,
    left: checkMobile() ? '60' : '100',
    mount: document.querySelector('#services-content'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

const achievementContent = () => {
  const newBox = new WinBox({
    title: 'Tech Stacks',
    width: checkMobile() ? '100%' : '45%',
    height: checkMobile() ? '100%' : '90%',
    top: checkMobile() ? '60' : '100',
    right: 50,
    bottom: 50,
    left: checkMobile() ? '60' : '100',
    mount: document.querySelector('#achievements'),
    onfocus: function () {
      this.setBackground('#00aa00');
    },
    onblur: function () {
      this.setBackground('#777');
    },
  });
  return newBox;
};

export { checkMobile, about, contact, techStacks, sideProjects, servicesContent, achievementContent };
