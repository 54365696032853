import '../App.css';
import Hidden from './Hidden';
import { about, contact, techStacks, sideProjects, achievementContent, servicesContent } from '../utils/winbox';

const data = [
  {
    url: 'https://www.linkedin.com/in/wasif-naquvi-4731ba126/',
    name: 'LinkedIn',
  },
  { url: 'https://www.instagram.com/wasif_naquvi/', name: 'Instagram' },
  { url: 'https://github.com/wasifsn', name: 'Github' },
  { url: 'https://www.facebook.com/wasif.naquvi.1/', name: 'Facebook' },
];

const Home = () => {
  return (
    <>
      <div className="container">
        <nav>
          <ul>
            <li id="about" onClick={() => about()}>
              /about
            </li>
            <li id="contact" onClick={contact}>
              /contact
            </li>
            <li id="tech" onClick={techStacks}>
              /techStacks
            </li>
            <li id="side" onClick={sideProjects}>
              /side Projects
            </li>
            <li id="achivement" onClick={achievementContent}>
              /Achievements
            </li>
            <li id="services" onClick={servicesContent}>
              /Services
            </li>
          </ul>
        </nav>

        <main>
          <h1>
            wasifNaquvi:$<span className="cursor">|</span>
          </h1>
          <h3>Crafting Cutting-Edge Solutions with Passion and Precision</h3>
          <h2 id="find-me">Find me online:</h2>

          <ul>
            {data.map((el) => (
              <li key={el.name}>
                <a href={el.url} target="_blank" rel="noreferrer">
                  {el.name}
                </a>
              </li>
            ))}
          </ul>
        </main>
      </div>
      <Hidden />
    </>
  );
};

export default Home;
